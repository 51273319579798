
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ReservationOperationLockList from "@/components/reservations/ReservationOperationLockList.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

//
export default defineComponent({
    name: "reservation-operation-lock-list",
    components: {
      ReservationOperationLockList,
    },
    props:{
        setValue: String,
        setCountry: String,
    },
    data: function () {
        return {
            myLoading: false,
        }
    },
    methods: {},

    setup() {
        return {
           // myList,
        };
    },
});
